export enum FeedbackEvents {
    FLASHCARDSET_LEARN = "FLASHCARDSET_LEARN",
    FLASHCARDSET_SPACED = "FLASHCARDSET_SPACED",
    FLASHCARDSET_PRACTICE = "FLASHCARDSET_PRACTICE",
    FLASHCARDSET_MATCH = "FLASHCARDSET_MATCH",
    FLASHCARDSET_REVIEW = "FLASHCARDSET_REVIEW",
    MEDIA_AI_NOTE = "MEDIA_AI_NOTE",
    MEDIA_AI_FLASHCARD = "MEDIA_AI_FLASHCARD",
    MEDIA_NOTE_PRACTICE = "MEDIA_NOTE_PRACTICE",
    MEDIA_SINGLE_CHAT = "MEDIA_SINGLE_CHAT",
    MEDIA_FULL_CHAT = "MEDIA_FULL_CHAT",
    HOME_EXPERIENCE = "HOME_EXPERIENCE",
    NOTE_AI_FLASHCARD = "NOTE_AI_FLASHCARD",
    EXPLAIN_WHY_IM_WRONG = "EXPLAIN_WHY_IM_WRONG",
}

export enum AIFeedbackTypes {
    MEDIA_NOTE = "MEDIA NOTE",
    MEDIA_FLASHCARD = "MEDIA FLASHCARD",
    NOTE_FLASHCARD = "NOTE FLASHCARD",
    CHAT = "CHAT",
}

export type FeedbackFollowup = Record<string, FeedbackObject>;

export type FeedbackObject = {
    title: string;
    desc?: string;
    options?: string[];
    followUps?: FeedbackFollowup;
    textInputPlaceholder?: string;
    showTextInputByDefault?: boolean;
};

// how it works:
// if an option is also described in followUps, then the popup will render title, desc and new options as per followups
// all "other" option will have a text input in the same popup screen unless the other option is also described in followUps.
// This is a long file, I'd recommend going to the bottom and cmd + click on the object value to navigate to the main event options and follow ups :)

const universalFollowUps: FeedbackFollowup = {
    Other: {
        title: "What could we do better?",
        desc: "We’d love to hear from you to improve Knowt!",
        textInputPlaceholder: "Type your feedback for us here!",
        // because this is a new followup, we need to tell the popup to show the text input by default
        showTextInputByDefault: true,
    },
    // the logic to show this is in FeedbackFlowContext
    "Anything else": {
        title: "Anything else you want us to know?",
        showTextInputByDefault: true,
        textInputPlaceholder: "Leave us feedback here!",
    },
};

// LEARN MODE FEEDBACK LIST
const feedbackForLearnMode: FeedbackObject = {
    title: "What do you not like in Learn Mode?",
    desc: "Select an option below or type in any feedback you have.",
    options: [
        "Too many ads",
        "Too many bugs",
        "It takes too long to complete",
        "I prefer Quizlet’s Learn Mode",
        "It’s missing some features",
        "Other",
    ],
    followUps: {
        "Too many ads": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["Remove ads in learn mode", "I want less ads outside of learn mode", "Other"],
        },
        "Too many bugs": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "I have blank option choices",
                "Writing questions are already answered",
                "My answers aren’t graded right",
                "It’s laggy",
                "Other",
            ],
            textInputPlaceholder: "Give us details about the bug you ran into.",
        },
        "It takes too long to complete": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "Mastered cards come up too often",
                "The questions are being repeated often and quickly",
                "Too many new terms introduced per round",
                "Other",
            ],
        },
        "I prefer Quizlet’s Learn Mode": {
            title: "What do you prefer about Quizlet?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "I like how quickly it goes",
                "I like seeing multiple choice questions before writing",
                "It has less ads",
                "I don’t see my mastered cards again there so often",
                "The shuffle works better there",
                "Other",
            ],
            textInputPlaceholder: "Tell us what you like about Quizlet's Learn Mode",
        },
        "It’s missing some features": {
            title: "What features do you want to see?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "More lenient grading system",

                "Auto playing audio",
                "Explanations on why I got a question wrong",
                "More challenging option choices from outside my set",
                "Ability to make custom multiple choice options",
                "Add a write mode",
                "Add some new games",
                "Add image occlusion",
                "Other",
            ],
            textInputPlaceholder: "Give us details about the feature you want.",
        },
    },
};

const feedbackForPracticeMode: FeedbackObject = {
    title: "What do you not like in Practice Test?",
    desc: "Select an option below or type in any feedback you have.",
    options: [
        "Too many ads",
        "Too many bugs",
        "It’s missing some features",
        "I prefer Quizlet’s Practice Test",
        "Other",
    ],
    followUps: {
        "Too many ads": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["Remove ads in practice test", "I want less ads outside of practice test", "Other"],
        },
        "Too many bugs": {
            title: "What issues do you have?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "I have blank option choices",
                "Writing questions are already answered",
                "My answers aren’t graded right",
                "It’s laggy",
                "Other",
            ],
            textInputPlaceholder: "Give us details about the bug you ran into.",
        },
        "It’s missing some features": {
            title: "What features do you want to see?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["Add some new games", "Other"],
            textInputPlaceholder: "Give us details about the feature you want.",
        },
        "I prefer Quizlet’s Practice Test": {
            title: "What do you prefer about Quizlet?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "I want to be able to disable images",
                "They have more lenient grading",
                "It has less ads",
                "Other",
            ],
            textInputPlaceholder: "Tell us what you like about Quizlet's Practice Test",
        },
    },
};

const feedbackForMatchMode: FeedbackObject = {
    title: "What do you not like in Matching?",
    desc: "Select an option below or type in any feedback you have.",
    options: [
        "Too many ads",
        "Too many bugs",
        "I prefer Quizlet’s Matching Mode",
        "It’s missing some features",
        "Other",
    ],
    followUps: {
        "Too many ads": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["Remove ads in matching", "I want less ads outside of matching", "Other"],
        },
        "Too many bugs": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["I see no cards sometimes", "It’s too hard to play on my device", "It’s laggy", "Other"],
            textInputPlaceholder: "Give us details about the bug you ran into.",
        },
        "It’s missing some features": {
            title: "What features do you want to see?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["Add some new games", "Other"],
            textInputPlaceholder: "Give us details about the feature you want.",
        },
        "I prefer Quizlet’s Matching Mode": {
            title: "What do you prefer about Quizlet?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "I like the way the grid is organized",
                "I like the scatter game there",
                "It has less ads",
                "Other",
            ],
            textInputPlaceholder: "Tell us what you like about Quizlet's Match Mode",
        },
    },
};

const feedbackForSpacedMode: FeedbackObject = {
    title: "What do you not like in Spaced Repetition?",
    desc: "Select an option below or type in any feedback you have.",
    options: [
        "Too many ads",
        "Too many bugs",
        "It takes too long to complete",
        "The time intervals are not right",
        "It’s missing some features",
        "I don’t like waiting between rounds",
        "I prefer Anki’s Spaced Repetition",
        "Other",
    ],
    followUps: {
        "Too many ads": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["Remove ads in spaced repetition", "I want less ads outside of spaced repetition", "Other"],
        },
        "Too many bugs": {
            title: "What issues do you have?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "The time intervals say zero",
                "I have blank option choices",
                "Writing questions are already answered",
                "My answers aren’t graded right",
                "It’s laggy",
                "Other",
            ],
            textInputPlaceholder: "Give us details about the bug you ran into.",
        },
        "It takes too long to complete": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "It takes too long for me to see the same questions",
                "The questions are being repeated often and quickly",
                "Too many new terms introduced per round",
                "Other",
            ],
        },
        "The time intervals are not right": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "The time intervals say zero",
                "The time intervals are too short",
                "The time intervals are too long",
                "Other",
            ],
        },
        "It’s missing some features": {
            title: "What features do you want to see?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "More lenient grading system",
                "Auto playing audio",
                "Explanations on why I got a question wrong",
                "More challenging option choices from outside my set",
                "Ability to make custom multiple choice options",
                "Add a write mode",
                "Add some new games",
                "Add image occlusion",
                "Other",
            ],
        },
        "I prefer Anki’s Spaced Repetition": {
            title: "What do you prefer about Anki?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "The cards are spaced out better",
                "I can embed videos on Anki",
                "I need image occlusion",
                "I need cloze (fill in the blank) cards",
                "I can add my own hints",
                "More formatting options",
                "I need Frozen Fields",
                "Other",
            ],
        },
    },
};

const feedbackForReviewMode: FeedbackObject = {
    title: "What do you not like in Flashcards mode?",
    desc: "Select an option below or type in any feedback you have.",
    options: [
        "Too many ads",
        "Too many bugs",
        "I prefer Quizlet’s Flashcard mode",
        "It’s missing some features",
        "Other",
    ],
    followUps: {
        "Too many ads": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["Remove ads in flashcards", "I want less ads outside of flashcards", "Other"],
        },
        "Too many bugs": {
            title: "What issues do you have?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "I have blank option choices",
                "Writing questions are already answered",
                "My answers aren’t graded right",
                "It’s laggy",
                "Other",
            ],
        },
        "I prefer Quizlet’s Flashcard mode": {
            title: "What do you prefer about Quizlet?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "I like the flashcard sorting feature they have",
                "It has less ads",
                "The shuffle works better there",
                "Other",
            ],
        },
        "It’s missing some features": {
            title: "What features do you want to see?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "More lenient grading system",
                "Auto playing audio",
                "Explanations on why I got a question wrong",
                "Add a write mode",
                "Add some new games",
                "Other",
            ],
        },
    },
};

const feedbackForMediaAiNotes: FeedbackObject = {
    title: "What do you not like about the note?",
    desc: "Select an option below or type in any feedback you have.",
    options: [
        "Note was too short ",
        "Note was too long",
        "Note was poor/missing information",
        "It wasn’t what I was expecting",
        "It’s messing up with my language",
        "I don’t like the headings it creates",
        "My note is messed up",
        "Other",
    ],
    followUps: {
        "Note was too short ": {
            title: "What's it missing?",
            desc: "Select an option below or type in any feedback you have.",
            options: [
                "It only covered topics from the first few pages/minutes",
                "It doesn’t cover all the key points mentioned",
                "It’s missing the smaller details in the content",
                "Other",
            ],
        },
        "Note was too long": {
            title: "What makes it too long?",
            desc: "Select an option below or type in any feedback you have.",
            options: [
                "It focuses too much on topics aren’t main ideas",
                "It goes into too much detail on each key topic",
                "It include the unnecessary intro & conclusion",
                "It includes the tangents the teacher takes",
                "Other",
            ],
        },
        "Note was poor/missing information": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["Make the notes cover more details", "Other"],
        },
        "It wasn’t what I was expecting": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "I expected it to be more organized",
                "It just looks like a really awful note",
                "It’s not in the right language",
                "I expected a study guide not an exact summary",
                "Other",
            ],
        },
        "It’s messing up with my language": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "It’s mixing in different languages",
                "I uploaded a different language and expected english",
                "Other",
            ],
        },
        "I don’t like the headings it creates": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["I don't want it to create a new heading per page", "Other"],
        },
        "My note is messed up": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["it's repeating information", "the content is out of order", "it won't let me scroll"],
        },
    },
};

const feedbackForMediaAiFlashcard: FeedbackObject = {
    title: "What do you not like about the flashcards?",
    desc: "Select an option below or type in any feedback you have.",
    options: [
        "It made too little flashcards",
        "It made too many flashcards",
        "The flashcards are poor/missing information",
        "It wasn’t what I was expecting",
        "It’s messing up with my language",
        "Other",
    ],
    followUps: {
        "It made too little flashcards": {
            title: "What's it missing?",
            desc: "Select an option below or type in any feedback you have.",
            options: [
                "It only covered topics from the first few pages/minutes",
                "It doesn’t cover all the key points mentioned",
                "It’s missing the smaller details in the content",
                "Other",
            ],
        },
        "It made too many flashcards": {
            title: "What makes it too many?",
            desc: "Select an option below or type in any feedback you have.",
            options: [
                "It focuses too much on topics aren’t main ideas",
                "It goes into too much detail on each key topic",
                "It include the unnecessary intro & conclusion",
                "It includes the tangents the teacher takes",
                "Other",
            ],
        },
        "The flashcards are poor/missing information": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["Make the flashcards cover more details", "Other"],
        },
        "It wasn’t what I was expecting": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "It just looks like a really awful set of cards",
                "It’s not in the right language",
                "I'm just kidding, they're great!",
                "Other",
            ],
        },
        "It’s messing up with my language": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "It’s mixing in different languages",
                "I uploaded a different language and expected english",
                "Other",
            ],
        },
    },
};

const feedbackForMediaNotePractice: FeedbackObject = {
    title: "What do you not like about the test?",
    desc: "Select an option below or type in any feedback you have.",
    options: [
        "It’s missing some features",
        "The questions are poor",
        "The questions are irrelevant",
        "There’s only 1 or 2 options choices instead of 4",
        "Other",
    ],
    followUps: {
        "It’s missing some features": {
            title: "What features do you want to see?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "I want to be able to disable images",
                "I want to be able to choose question types",
                "I want it to explain why I got the question wrong",
                "Other",
            ],
            textInputPlaceholder: "Give us details about the feature you want.",
        },
        "The questions are poor": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "Make the questions more specific to the file I uploaded",
                "Have the questions cover more key points",
                "Have more questions that cover the smaller details",
                "Questions are too easy",
                "The questions are too hard",
                "Other",
            ],
            textInputPlaceholder: "Give us details about the feature you want.",
        },
        "The questions are irrelevant": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "Make the questions more specific to the file I uploaded",
                "Have the questions cover more key points",
                "Have more questions that cover the smaller details",
                "Other",
            ],
        },
    },
};

const feedbackForMediaSingleChat: FeedbackObject = {
    title: "What was wrong with that reply?",
    desc: "Select an option below or type in any feedback you have.",
    options: [
        "It was irrelevant",
        "It didn’t answer my question properly",
        "It was in the wrong lanugage",
        "It’s not what I was expecting",
        "Other",
    ],
};

const feedbackForMediaFullChat: FeedbackObject = {
    title: "What do you not like about the chat?",
    desc: "Select an option below or type in any feedback you have.",
    options: [
        "The replies from Kai are not relevant",
        "Didn’t know what to ask Kai",
        "The response lacked details",
        "The response was too long",
        "It’s messing up with my language",
        "Other",
    ],
    followUps: {
        "The replies from Kai are not relevant": {
            title: "What's it missing?",
            desc: "Select an option below or type in any feedback you have.",
            options: [
                "It only covered topics from the first few pages/minutes",
                "It doesn’t cover all the key points mentioned",
                "It’s missing the smaller details",
                "Other",
            ],
        },
        "Didn’t know what to ask Kai": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "It’s too open ended for me",
                "I don’t know what he can answer",
                "I would like better suggestions",
                "Other",
            ],
        },
        "The response lacked details": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["Cover more of the key details", "Bring in more information from the file", "Other"],
        },
        "The response was too long": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["Make it more concise on the information", "Only cover key details", "Other"],
        },
        "It’s messing up with my language": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "It’s mixing in different languages",
                "I uploaded a different language and expected english",
                "Other",
            ],
        },
    },
};

const feedbackForHomeExperience: FeedbackObject = {
    title: "What do you not like about Knowt?",
    desc: "Select an option below or type in any feedback you have.",
    options: [
        "Way too many ads",
        "Too many bugs",
        "I prefer Quizlet",
        "It’s missing some features",
        "It’s really confusing to use",
        "I can't find features I used to see before",
        "Other",
    ],
    followUps: {
        "Way too many ads": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "Remove ads on the home screen",
                "The placement of ads is getting distracting",
                "I’m fine with ads, just show less when I study",
                "Knowt feels more cluttered than Quizlet",
                "Get rid of all ads",
                "Other",
            ],
        },
        "Too many bugs": {
            title: "What issues do you have?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "It’s laggy",
                "My flashcards don't save properly when I add them",
                "Nobody gets back to me when I contact about bugs",
                "I end up at a lot of 404 screens",
                "The AI features don't work for me",
                "I'm not able to scroll at times",
                "Learn mode doesn’t work well for me ",
                "Things look weird on my device",
                "Other",
            ],
        },
        "I prefer Quizlet": {
            title: "What do you like better about Quizlet?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "It’s less laggy",
                "It’s more dependable - less bugs",
                "It’s less confusing to navigate",
                "My teacher's flashcards are on there",
                "My friends` flashcards are there",
                "I don’t like creating flashcards on Knowt",
                "Quizlet's AI is easier to use",
                "There’s too much going on in Knowt",
                "Other",
            ],
        },
        "It’s missing some features": {
            title: "What features do you want to see?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "Add some new games",
                "Explanations on why I got a question wrong",
                "More challenging option choices from outside my set",
                "Add image occlusion",
                "Ability to make custom multiple choice questions",
                "Other",
            ],
        },
    },
};

const feedbackForNoteAIFlashcards: FeedbackObject = {
    title: "What do you not like about the flashcards?",
    desc: "Select an option below or type in any feedback you have.",
    options: [
        "It made too little flashcards",
        "It made too many flashcards",
        "The flashcards are poor/missing information",
        "It wasn’t what I was expecting",
        "It’s messing up with my language",
        "Other",
    ],
    followUps: {
        "It made too little flashcards": {
            title: "What's it missing?",
            desc: "Select an option below or type in any feedback you have.",
            options: [
                "It only covered topics from the first few paragraph",
                "It doesn’t cover all the key points mentioned",
                "It’s missing the smaller details in the content",
                "Other",
            ],
        },
        "It made too many flashcards": {
            title: "What makes it too many?",
            desc: "Select an option below or type in any feedback you have.",
            options: [
                "It focuses too much on topics aren’t main ideas",
                "It goes into too much detail on each key topic",
                "It include the unnecessary intro & conclusion",
                "Other",
            ],
        },
        "The flashcards are poor/missing information": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: ["Make the flashcards cover more details", "Other"],
        },
        "It wasn’t what I was expecting": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "It just looks like a really awful set of cards",
                "It’s not in the right language",
                "I'm just kidding, they're great!",
                "Other",
            ],
        },
        "It’s messing up with my language": {
            title: "What could we do better?",
            desc: "We’d love to hear from you to improve Knowt!",
            options: [
                "It’s mixing in different languages",
                "I uploaded a different language and expected english",
                "Other",
            ],
        },
    },
};

const feedbackForExplainWhyImWrong: FeedbackObject = {
    title: "What do you not like about the explanation?",
    desc: "Select an option below or type in any feedback you have.",
    options: [
        "I'm learning vocabulary so it's not relevant",
        "The explanation is factually incorrect",
        "I want a more detailed explanation",
        "The explanations are too long",
        "Other",
    ],
    followUps: {},
};

const passFeedbackWithUniversalFollowUps = (feedback: FeedbackObject) => {
    return {
        ...feedback,
        followUps: {
            ...feedback.followUps,
            ...universalFollowUps,
        },
    };
};

export const feedbackPopupContent = {
    [FeedbackEvents.FLASHCARDSET_LEARN]: passFeedbackWithUniversalFollowUps(feedbackForLearnMode),
    [FeedbackEvents.FLASHCARDSET_PRACTICE]: passFeedbackWithUniversalFollowUps(feedbackForPracticeMode),
    [FeedbackEvents.FLASHCARDSET_MATCH]: passFeedbackWithUniversalFollowUps(feedbackForMatchMode),
    [FeedbackEvents.FLASHCARDSET_SPACED]: passFeedbackWithUniversalFollowUps(feedbackForSpacedMode),
    [FeedbackEvents.FLASHCARDSET_REVIEW]: passFeedbackWithUniversalFollowUps(feedbackForReviewMode),
    [FeedbackEvents.MEDIA_AI_NOTE]: passFeedbackWithUniversalFollowUps(feedbackForMediaAiNotes),
    [FeedbackEvents.MEDIA_AI_FLASHCARD]: passFeedbackWithUniversalFollowUps(feedbackForMediaAiFlashcard),
    [FeedbackEvents.MEDIA_NOTE_PRACTICE]: passFeedbackWithUniversalFollowUps(feedbackForMediaNotePractice),
    [FeedbackEvents.MEDIA_SINGLE_CHAT]: passFeedbackWithUniversalFollowUps(feedbackForMediaSingleChat),
    [FeedbackEvents.MEDIA_FULL_CHAT]: passFeedbackWithUniversalFollowUps(feedbackForMediaFullChat),
    [FeedbackEvents.HOME_EXPERIENCE]: passFeedbackWithUniversalFollowUps(feedbackForHomeExperience),
    [FeedbackEvents.NOTE_AI_FLASHCARD]: passFeedbackWithUniversalFollowUps(feedbackForNoteAIFlashcards),
    [FeedbackEvents.EXPLAIN_WHY_IM_WRONG]: passFeedbackWithUniversalFollowUps(feedbackForExplainWhyImWrong),
};
