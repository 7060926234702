"use client";

import { ExclusiveUserContent } from "@knowt/syncing/types/common";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { Key, Lock, Share, Unlock } from "lucide-react";
import React, { useMemo } from "react";
// eslint-disable-next-line no-restricted-imports
import { SxProps } from "@mui/material";
import Circular from "@/components/Circular";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { shareOrCopy } from "@/utils/clipboard";
import { iconSizes } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import toast from "react-hot-toast";
import { isFolder } from "@knowt/syncing/utils/dataCleaning";
import { ItemType } from "@knowt/syncing/graphql/schema";
import { assertTruthy } from "@knowt/syncing/utils/assertions";
import { useClassSharingPopupContextSelector } from "./hooks/useClassSharingPopupContext";
import CircularButton from "@/components/CircularButton";

const ClassSharingPillWithHandler = ({
    media,
    note,
    flashcardSet,
    folder,
    sx,
    circularSx,
    iconColor,
    iconOnly,
    ...props
}: ExclusiveUserContent & {
    onClose?: ({ isPublic, prevPublic }: { isPublic?: boolean | null; prevPublic?: boolean | null }) => void;
    mutate?: () => void;
    sx?: SxProps;
    circularSx?: Omit<SxProps, "backgroundColor"> & { backgroundColor?: string };
    iconColor?: string;
    iconOnly?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
    const { userId } = useCurrentUser();
    const openClassSharingPopup = useClassSharingPopupContextSelector(state => state.openClassSharingPopup);

    const item = media || note || flashcardSet || folder;

    const itemType = useMemo(() => {
        if (media) return ItemType.MEDIA;
        if (note) return ItemType.NOTE;
        if (flashcardSet) return ItemType.FLASHCARDSET;
        if (folder) return ItemType.FOLDER;
        assertTruthy(false, "itemType should be defined");
    }, [media, note, flashcardSet, folder]);

    const itemId = useMemo(() => {
        if (media) return media.mediaId;
        if (note) return note.noteId;
        if (flashcardSet) return flashcardSet.flashcardSetId;
        if (folder) return folder.folderId;
        assertTruthy(false, "itemId should be defined");
    }, [media, note, flashcardSet, folder]);

    const allSections = !item.sections;
    const noSections = item.sections?.length === 0;

    const isOwner = item?.userId === userId;

    const handleManageSharing = async () => {
        if (media) {
            return toast.error("coming soon!");
        }

        if (isOwner) {
            openClassSharingPopup({
                fileId: itemId,
                fileType: itemType,
            });
        } else {
            await shareOrCopy({ title: (isFolder(item) ? item?.name : item?.title) || "Knowt" });
        }
    };

    if (iconOnly) {
        return (
            <CircularButton
                radius={"3.8rem"}
                style={{
                    backgroundColor: allSections ? themeColors.icon1 : noSections ? "#000" : "#C83E80",
                    ...circularSx,
                }}
                onClick={handleManageSharing}>
                {!isOwner ? (
                    <Share size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                ) : allSections ? (
                    <Unlock size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                ) : noSections ? (
                    <Lock size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                ) : (
                    <Key size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                )}
            </CircularButton>
        );
    }

    return (
        <CircularRectTextButton
            {...props}
            className="secondaryTextBold1"
            sx={{
                backgroundColor: themeColors.neutralWhite,
                color: themeColors.neutralBlack,
                padding: spacing.XS,
                paddingRight: spacing.SM,
                gap: spacing.XS,
                width: "19rem",
                height: "4rem",
                justifyContent: "flex-start",
                "&:hover": {
                    backgroundColor: themeColors.neutralBlack,
                    color: themeColors.neutralWhite,
                },
                ...sx,
            }}
            onClick={handleManageSharing}
            tooltip={"edit sharing settings"}>
            <Circular
                radius={"3rem"}
                style={{
                    backgroundColor: allSections ? themeColors.icon1 : noSections ? "#000" : "#C83E80",
                    ...circularSx,
                }}>
                {!isOwner ? (
                    <Share size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                ) : allSections ? (
                    <Unlock size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                ) : noSections ? (
                    <Lock size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                ) : (
                    <Key size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                )}
            </Circular>
            <p>{!isOwner ? "share" : "manage sharing"}</p>
        </CircularRectTextButton>
    );
};

export default ClassSharingPillWithHandler;
