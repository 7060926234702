"use client";

import { Next13ProgressBar } from "next13-progressbar";

const TopProgressBar = () => {
    return (
        <Next13ProgressBar
            height="4px"
            color="#50d2c2"
            options={{
                easing: "ease",
                speed: 1000,
                showSpinner: false,
                minimum: 0.4,
            }}
        />
    );
};

export default TopProgressBar;
