import { createQueryStrings } from "@knowt/syncing/utils/genericUtils";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import { getNoteUrl } from "./url";

export const shareOrCopy = async (shareData: ShareData) => {
    const url = window.location.origin + window.location.pathname + createQueryStrings({ share: "direct" });
    // According to docs, navigator.canShare can only be used over HTTPS
    shareData = { url, ...shareData };
    if (!__DEV__ && navigator.share && navigator.canShare(shareData)) {
        await navigator.share(shareData);
        toast.success("Link copied to clipboard!");
    } else {
        await copyToClipboard(shareData.url ?? url);
    }
};

export const copyToClipboard = async (text: string, options?: object) => {
    if (copy(text, options)) {
        toast.success("Copied to clipboard!");
    } else {
        throw new Error("Clipboard utils not supported on this browser");
    }
};

export const copyNoteUrl = ({
    noteId,
    title,
    extraPaths = [],
}: {
    noteId: string;
    title: string;
    extraPaths?: string[];
}) => copyToClipboard(encodeURI(window.location.origin + getNoteUrl({ noteId, title, extraPaths })));
