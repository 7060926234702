import { UserAlertsType } from "@/hooks/guidedEvents/UserAlerts";
import { GuidedEventObject } from "@/hooks/guidedEvents/guidedEvents";

export enum UpdateUserAlertsMethod {
    "ADD_TO_DONE",
    "REMOVE_FROM_DONE",
    "ADD_TO_TODO",
    "REMOVE_FROM_TODO",
    "DECREMENT_VISIT_COUNT",
}

export type UpdateUserAlerts =
    | {
          method: UpdateUserAlertsMethod.ADD_TO_DONE | UpdateUserAlertsMethod.REMOVE_FROM_DONE;
          eventName: string;
          event?: never;
      }
    | {
          method:
              | UpdateUserAlertsMethod.ADD_TO_TODO
              | UpdateUserAlertsMethod.REMOVE_FROM_TODO
              | UpdateUserAlertsMethod.DECREMENT_VISIT_COUNT;
          eventName?: never;
          event: GuidedEventObject;
      };

export const generateUpdatedUserAlerts = ({
    updatedFields,
    userAlerts,
}: {
    updatedFields: UpdateUserAlerts;
    userAlerts: UserAlertsType;
}) => {
    switch (updatedFields.method) {
        /**
         * add an event to done in UserAlerts, this is for closing an event -> on popup close, on report.finish() etc..
         */
        case UpdateUserAlertsMethod.ADD_TO_DONE:
            return {
                ...userAlerts,
                done: [...new Set([...userAlerts.done, updatedFields.eventName])],
            };

        /**
         * remove from done, most likely useful if we completely got rid of an event, so no need to keep it in done and waste space.
         * Sometimes useful for repeating events by making use of todo and action.REMOVE
         */
        case UpdateUserAlertsMethod.REMOVE_FROM_DONE:
            return {
                ...userAlerts,
                done: userAlerts.done.filter(event => event !== updatedFields.eventName),
            };
        /**
         *  Add event to todo in UserAlerts, pretty obvious
         */

        case UpdateUserAlertsMethod.ADD_TO_TODO:
            return {
                ...userAlerts,
                todo: [...userAlerts.todo, updatedFields.event],
            };

        /**
         * Remove a todo event, use case: when visitCount hits 0, we stage it and remove it from todo
         */
        case UpdateUserAlertsMethod.REMOVE_FROM_TODO:
            return {
                ...userAlerts,
                todo: userAlerts.todo.filter(
                    event =>
                        event.eventName !== updatedFields.event.eventName && event.action !== updatedFields.event.action
                ),
            };

        /**
         * Decrement
         */
        case UpdateUserAlertsMethod.DECREMENT_VISIT_COUNT:
            return {
                ...userAlerts,
                todo: userAlerts.todo.map(event => {
                    if (event.eventName === updatedFields.event.eventName) {
                        return {
                            ...event,
                            visitCount: event.visitCount > 0 ? event.visitCount - 1 : 0,
                        };
                    }
                    return event;
                }),
            };
    }
};
