"use client";

import packageInfo from "@/package.json";
import dynamic from "next/dynamic";
import { Suspense, useEffect } from "react";
import { useAuthPopupContextSelector, useAuthSlideContextSelector } from "@/features/Auth";
import LazyLoaded from "@/components/LazyLoaded";
import { useAiPrivacyContextSelector } from "@/components/Popup/AiPopup/AiPrivacyPopupContext";
import { useUpgradePopupContextSelector } from "@/components/Popup/Subscription/UpgradePopupContext";
import { useDialogContextSelector } from "@/contexts/DialogContext";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { AuthHubListener } from "./AuthHubListener";
import { themeColors } from "@/utils/themeColors";
import SuspensedMobileAppPopup from "./SuspensedMobileAppPopup";
import { useMultiTabsEditingWarningContextSelector } from "@/contexts/MultiTabsEditingWarningContext";

const UpgradeOrResubscribePopup = dynamic(() => import("@/components/Popup/Subscription/UpgradeOrResubscribePopup"));

const MultiTabsEditingWarningPopup = dynamic(
    () => import("@/components/Popup/MultiTabsEditingWarningPopup/MultiTabsEditingWarningPopup")
);

const AiPrivacyPopup = dynamic(() => import("@/components/Popup/AiPopup/AiPrivacyPopup"));
const Toaster = dynamic(() => import("react-hot-toast").then(m => m.Toaster));
const AuthPopup = dynamic(() => import("@/features/Auth").then(p => p.AuthPopup));
const AuthSlideDialog = dynamic(() => import("@/features/Auth").then(p => p.AuthSlideDialog));
const ConfirmDialog = dynamic(() => import("@/components/Popup/ConfirmDialog"));

// NOTE: sometimes our page is unscrollable for some reason
// for now we assume that there is some ads that modify the body overflow for some reason. This trick is to set the body scroll back to auto
const ObserveBlockedBodyScroll = () => {
    useEffect(() => {
        const body = document.body;

        const observer = new MutationObserver((mutationList, _observer) => {
            for (const mutation of mutationList) {
                if (mutation.attributeName === "style") {
                    if (body.style.overflow !== "auto") {
                        body.style.overflow = "auto";
                    }
                }
            }
        });

        observer.observe(body, { attributes: true });

        return () => observer.disconnect();
    }, []);

    return null;
};

export default function TopLevelComponents() {
    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(`You are running Knowt v.${packageInfo.version}`);
    }, []);

    const isAuthSlideOpen = useAuthSlideContextSelector(state => state.authSlideStates?.isOpen);
    const isAuthPopupOpen = useAuthPopupContextSelector(state => state.authPopupStates?.isOpen);
    const isConfirmDialogOpen = useDialogContextSelector(state => state.confirmDialog.open);
    const isAiPrivacyPopupOpen = useAiPrivacyContextSelector(state => state.isAiPrivacyPopupOpen);
    const isUpgradePopupOpen = useUpgradePopupContextSelector(state => state.isOpen);
    const isMultiTabsEditingWarningOpen = useMultiTabsEditingWarningContextSelector(state => state.isOpen);

    return (
        <>
            <ObserveBlockedBodyScroll />
            <AuthHubListener />
            <LazyLoaded load={isMultiTabsEditingWarningOpen}>
                <MultiTabsEditingWarningPopup />
            </LazyLoaded>
            <LazyLoaded load={isConfirmDialogOpen}>
                <ConfirmDialog />
            </LazyLoaded>
            <LazyLoaded load={isAuthPopupOpen}>
                <AuthPopup />
            </LazyLoaded>
            <LazyLoaded load={isUpgradePopupOpen}>
                <UpgradeOrResubscribePopup />
            </LazyLoaded>
            <LazyLoaded load={isAiPrivacyPopupOpen}>
                <AiPrivacyPopup />
            </LazyLoaded>

            <LazyLoaded load={isAuthSlideOpen}>
                <GoogleReCaptchaProvider reCaptchaKey="6LfT_R4oAAAAAG-ZcqjXS6luIzLBWsdN0Mw2Mb6y">
                    <AuthSlideDialog />
                </GoogleReCaptchaProvider>
            </LazyLoaded>

            <Suspense>
                <SuspensedMobileAppPopup />
            </Suspense>
            <Toaster
                position="top-center"
                reverseOrder
                containerStyle={{
                    zIndex: 999_999,
                }}
                toastOptions={{
                    style: {
                        backgroundColor: themeColors.neutralBlack,
                        color: themeColors.neutralWhite,
                        maxWidth: "50rem",
                    },
                    error: { duration: 5_000, iconTheme: { primary: "#f18888", secondary: themeColors.pureWhite } },
                    loading: { iconTheme: { primary: "#50d2c2", secondary: themeColors.pureWhite } },
                    success: { duration: 5_000, iconTheme: { primary: "#50d2c2", secondary: themeColors.pureWhite } },
                }}
            />
        </>
    );
}
