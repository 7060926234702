"use client";

import { useCallback, useState } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";

type InitialAccountSetupContextValue = {
    isExamSelectionOpen: boolean;
    openExamSelection: () => void;
    closeExamSelection: () => void;
};

const InitialAccountSetupContext = createContext<InitialAccountSetupContextValue | null>(null);

export const InitialAccountSetupContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [isExamSelectionOpen, setIsExamSelectionOpen] = useState(false);

    const openExamSelection = useCallback(() => {
        // we only want to explicitly open the exam selection if the initial account setup isn't open
        setIsExamSelectionOpen(!isExamSelectionOpen);
    }, [isExamSelectionOpen]);

    const closeExamSelection = useCallback(() => {
        setIsExamSelectionOpen(false);
    }, []);

    return (
        <InitialAccountSetupContext.Provider
            value={{
                isExamSelectionOpen,
                openExamSelection,
                closeExamSelection,
            }}>
            {children}
        </InitialAccountSetupContext.Provider>
    );
};

export const useInitialAccountSetupContextSelector = <T,>(selector: (state: InitialAccountSetupContextValue) => T) =>
    useContextSelector(InitialAccountSetupContext, selector);
