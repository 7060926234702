"use client";
import { fetchPostJSON } from "@/fetchFunctions/fetchWrappers";
import { callCheckPassword } from "@/hooks/sharing/graphqlUtils";
import { ItemType } from "@knowt/syncing/graphql/schema";
import { platform } from "@knowt/syncing/platform";
import { TIME } from "@knowt/syncing/utils/dateTimeUtils";
import { ArrowRight } from "lucide-react";
import Image from "next/image";
import { useState } from "react";
import { toast } from "react-hot-toast";
import style from "./passwordProtected.module.css";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import Footer from "@/components/Footer";
import LoadingAnimation from "@/components/LoadingAnimation";
import LogPageView from "@/components/LogPageVisit";
import NavBar from "@/features/NavBar";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import BorderColoredInput from "@/components/styled/input/BorderColoredInput";
import { iconSizes } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { ASSETS_URL } from "@/config/deployConstants";
import br from "@/styles/breakpoints.module.css";

const keyIcon = `${ASSETS_URL}/images/key-icon.svg`;

const PasswordProtectedComponent = ({ mutate, type, itemId }: { mutate?: any; type: ItemType; itemId: string }) => {
    const [password, setPassword] = useState("");
    const [isPasswordAuthenticating, setIsPasswordAuthenticating] = useState(false);

    const handleSubmitClick = async () => {
        if (!password) {
            toast.error("Please enter a password");
            return;
        }

        setIsPasswordAuthenticating(true);

        const { isAuthenticated } = await callCheckPassword({ password: password, type, itemId });

        if (isAuthenticated) {
            const storage = await platform.storage();
            await storage.setWithExpiry(`${type}-${itemId}-pwd`, password, 3 * TIME.HOUR);
            await fetchPostJSON("/api/cookies/passwords", { password, type, itemId });
            mutate?.();
            window.location.reload();
        } else {
            toast.error("Incorrect password");
        }

        setIsPasswordAuthenticating(false);
    };

    const renderPageContent = () => (
        <FlexColumnAlignJustifyCenter
            style={{
                minHeight: "95vh",
                gap: spacing.MD,
                padding: "3.6rem",
            }}>
            <FlexRowAlignCenter
                className={br.smDownColumn}
                style={{
                    gap: spacing.LG,
                    maxWidth: "70%",
                }}>
                <Image
                    className={br.smDownDisplayNone}
                    src={keyIcon}
                    alt={"key"}
                    width={"190"}
                    height={"190"}
                    style={{
                        maxWidth: "20%",
                        objectFit: "contain",
                    }}
                />
                <Image
                    className={br.smUpDisplayNone}
                    src={keyIcon}
                    alt={"key"}
                    width={"190"}
                    height={"190"}
                    style={{
                        maxWidth: "80%",
                        objectFit: "contain",
                    }}
                />
                <FlexColumn
                    style={{
                        gap: spacing.LG,
                    }}>
                    <FlexColumn
                        className={br.smDownDisplayNone}
                        style={{
                            gap: spacing.XXS,
                        }}>
                        <p className="heading4">Do you have a password to open the set?</p>
                        <p className="body1">
                            Looks like this note/flashcard set was shared by a Knowt user on our Pro Plan. Enter the
                            password to unlock it.
                        </p>
                    </FlexColumn>
                    <FlexColumn
                        className={br.smUpDisplayNone}
                        style={{
                            gap: spacing.XXS,
                            padding: "0 2rem",
                            textAlign: "center",
                        }}>
                        <p className="heading5">Do you have a password to open the set?</p>
                        <p className="body2">
                            Looks like this note/flashcard set was shared by a Knowt user on our Pro Plan. Enter the
                            password to unlock it.
                        </p>
                    </FlexColumn>
                    <FlexRowAlignCenter
                        className={style.passwordInputContainer}
                        style={{ gap: spacing.SM, maxWidth: "80%" }}>
                        <BorderColoredInput
                            style={{
                                width: "30rem",
                                height: "6rem",
                                borderRadius: "1.5rem",
                            }}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            onKeyDown={e => {
                                if (e.key === "Enter") {
                                    handleSubmitClick();
                                }
                            }}
                            placeholder="Enter password"
                        />
                        <CircularRectTextButton
                            onClick={handleSubmitClick}
                            className="bodyBold2"
                            sx={{
                                width: "11rem",
                                height: "6rem",
                                backgroundColor: themeColors.primary,
                                color: themeColors.pureWhite,
                            }}>
                            {isPasswordAuthenticating ? (
                                <LoadingAnimation size={iconSizes.MD} color={"white"} />
                            ) : (
                                <FlexRowAlignCenter
                                    style={{
                                        gap: spacing.XXS,
                                    }}>
                                    next
                                    <ArrowRight size={iconSizes.MD} color={"white"} />
                                </FlexRowAlignCenter>
                            )}
                        </CircularRectTextButton>
                    </FlexRowAlignCenter>
                </FlexColumn>
            </FlexRowAlignCenter>
        </FlexColumnAlignJustifyCenter>
    );

    return (
        <>
            <LogPageView page={`Password Protected ${type} - Opened`} />
            <NavBar />
            {renderPageContent()}
            <Footer ad={true} />
        </>
    );
};

export default PasswordProtectedComponent;
