import { useEffect } from "react";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { signOutListeners } from "@knowt/syncing/hooks/user/auth";
import Mixpanel from "./Mixpanel";
import { isUnderAge } from "@knowt/syncing/hooks/user/utils";

export const useMixpanelUserSetup = () => {
    const { userId, user } = useCurrentUser();

    useEffect(() => {
        if (userId) {
            if (isUnderAge(user?.birthday, 13)) {
                // respect DNT now
                Mixpanel.init(false);
            }

            Mixpanel.identify(userId);
            Mixpanel.people.set({ ...user, $email: user?.Email, $name: user?.Name });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    useEffect(() => {
        return signOutListeners.register(() => {
            Mixpanel.reset();
        });
    }, []);
};
