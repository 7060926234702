"use client";

import { copyToClipboard } from "@/utils/clipboard";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";

const MaybeCopyReferralCode = () => {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    useEffect(() => {
        const ref_v2 = searchParams.get("ref_v2");

        if (ref_v2) {
            copyToClipboard(`${pathname}?${searchParams.toString()}`);
        }
    }, []);

    return null;
};

export default MaybeCopyReferralCode;
