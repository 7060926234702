import { checkPassword, createPassword } from "@/graphql/mutations";
import { ItemType } from "@knowt/syncing/graphql/schema";
import { client } from "@/utils/SyncUtils";

export const callCreatePassword = async ({
    itemId,
    password,
    type,
}: {
    itemId?: string;
    userId?: string;
    password?: string;
    type?: ItemType;
}) => {
    if (!itemId || !password || !type) return null;

    const input = { id: itemId, password, type };

    return client
        .mutate({
            mutation: createPassword,
            variables: { input },
        })
        .then(({ data }) => data.createPassword);
};

export const callCheckPassword = async ({
    itemId,
    password,
    type,
}: {
    itemId?: string;
    password?: string;
    type?: ItemType;
}): Promise<{ isAuthenticated: boolean }> => {
    if (!itemId || !password || !type) return { isAuthenticated: true };

    return client
        .mutate({
            mutation: checkPassword,
            variables: { input: { id: itemId, password, type } },
        })
        .then(({ data }) => ({ isAuthenticated: data.checkPassword }))
        .catch(() => ({ isAuthenticated: false }));
};

export const confirmPassword = async ({ itemId, password, type }) => {
    try {
        if (!itemId || !password || !type) return false;

        const { isAuthenticated } = await callCheckPassword({ itemId, password, type });

        return isAuthenticated;
    } catch {
        return false;
    }
};
