"use client";
import React, { useCallback, useMemo, useState } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import dynamic from "next/dynamic";
import LazyLoaded from "@/components/LazyLoaded";
import { ItemType } from "@knowt/syncing/graphql/schema";

const ClassSharePopup = dynamic(() => import("../components/ClassSharePopup"));

type ClassSharingPopupContextValue = {
    openClassSharingPopup: ({ fileId, fileType }: { fileId: string; fileType: ItemType }) => void;
};

const ClassSharingPopupContext = createContext<ClassSharingPopupContextValue | null>(null);

export const ClassSharingPopupContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [isClassSharingPopupOpen, setIsClassSharingPopupOpen] = useState(false);

    const [value, setValue] = useState<{
        fileId: string;
        fileType: ItemType;
    } | null>(null);

    const resetAllState = () => {
        setIsClassSharingPopupOpen(false);
        setValue(null);
    };

    const openClassSharingPopup = useCallback(({ fileId, fileType }: { fileId: string; fileType: ItemType }) => {
        setValue({ fileId, fileType });
        setIsClassSharingPopupOpen(true);
    }, []);

    return (
        <ClassSharingPopupContext.Provider value={useMemo(() => ({ openClassSharingPopup }), [openClassSharingPopup])}>
            {children}
            <LazyLoaded load={isClassSharingPopupOpen && !!value}>
                <ClassSharePopup
                    isOpen={isClassSharingPopupOpen}
                    onClose={resetAllState}
                    // we know that value is not null here because of the LazyLoad
                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                    fileId={value?.fileId!}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                    fileType={value?.fileType!}
                />
            </LazyLoaded>
        </ClassSharingPopupContext.Provider>
    );
};

export const useClassSharingPopupContextSelector = <T,>(selector: (state: ClassSharingPopupContextValue) => T) =>
    useContextSelector(ClassSharingPopupContext, selector);
