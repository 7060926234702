import { themeColors } from "@/utils/themeColors";
import { isDarkColor } from "@knowt/syncing/utils/genericUtils";
import noop from "@knowt/syncing/utils/noop";
import { Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import ConditionalTooltip from "./wrappers/ConditionalTooltip";
import { ConditionalLink } from "./ConditionalLink";

export type TabSwitcherProps = {
    tabs: {
        label: string;
        tooltip?: string;
        disabled?: boolean;
        onClick?: () => void;
        href?: string;
        className?: string;
    }[];
    selectedTab?: number;
    setSelectedTab?: (index: number) => void;
    tabColor?: string | null | undefined;
    width?: string;
    height?: string;
    padding?: string;
    margin?: string;
    fontSize?: string;
    fontWeight?: string;
    color?: string;
    containerStyle?: React.CSSProperties;
    className?: string;
    alternate?: boolean;
};

const TabSwitcher = ({
    tabs,
    selectedTab = 0,
    setSelectedTab = noop,
    tabColor,
    width = "13.2rem",
    height = "0",
    padding = "0",
    margin = "0",
    fontSize = "1.7rem",
    fontWeight = "600",
    color = "",
    containerStyle = {},
    className,
    alternate = false,
}: TabSwitcherProps) => {
    const tabRef = useRef({});
    const tabContainerRef = useRef<HTMLDivElement>(null);

    const widthValue = eval(width.split("rem")[0]);

    const horizontalPaddingAndMargin =
        eval(padding.split(" ").slice(padding.split(" ").length - 1)[0]) +
        (widthValue > 11.5 ? 0.7 : widthValue > 10 ? 0.6 : 0.5); // arbitrary values from trial-and-error

    const [hoveredTab, setHoveredTab] = useState<number | null>(null);

    const [currentTabLeft, setCurrentTabLeft] = useState(
        `${selectedTab * (widthValue + horizontalPaddingAndMargin * 2)}rem`
    );

    const handleTabChange = index => {
        setSelectedTab(index);
        setCurrentTabLeft(`${tabRef.current[index]?.offsetLeft - 5}px`); // 5 is from trail-and-error :)
    };

    useEffect(() => {
        const resizeHandler = () => setCurrentTabLeft(`${tabRef.current[selectedTab]?.offsetLeft - 5}px`);
        resizeHandler();

        const container = tabContainerRef.current;

        // we need this listener to update the position of the current tab indicator when the window is resized as the width is changed by parent per smDown classes (example in search page)
        container?.addEventListener("resize", resizeHandler);
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
            container?.removeEventListener("resize", resizeHandler);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabContainerRef.current]);

    return (
        <div
            ref={tabContainerRef}
            className={className}
            data-testid="tab-switcher"
            style={{
                position: "relative",
                display: "inline-flex",
                borderRadius: 50,
                backgroundColor: alternate ? themeColors.neutral1 : themeColors.neutralWhite,
                margin: margin,
                height: height || undefined,
                padding: "0 0.6rem",
                ...containerStyle,
            }}>
            {tabs.map(({ label, tooltip, disabled = false, onClick, href, className }, index) => (
                <ConditionalTooltip key={label} tooltip={tooltip}>
                    <Box
                        component={href ? "span" : "div"}
                        ref={ref => (tabRef.current = { ...tabRef.current, [index]: ref })}
                        onMouseEnter={() => setHoveredTab(index)}
                        onMouseLeave={() => setHoveredTab(null)}
                        sx={{
                            minWidth: width,
                            maxWidth: width,
                            padding,
                            borderRadius: 50,
                            margin: `0.5rem`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: alternate ? themeColors.neutral1 : themeColors.neutralWhite,
                            cursor: disabled ? "default" : "pointer",
                            ...(selectedTab !== index && {
                                "&:hover": {
                                    backgroundColor: alternate ? themeColors.neutral2 : themeColors.neutral1,
                                },
                            }),
                            transition: "background-color 0.2s",
                        }}
                        onClick={() => {
                            if (href) return;
                            if (disabled) return;

                            handleTabChange(index);
                            if (onClick) onClick();
                        }}>
                        <span
                            className={className}
                            style={{
                                fontFamily: "var(--knowt-font-name)",
                                fontSize: fontSize || "1.8rem",
                                fontWeight: fontWeight,
                                color:
                                    color || selectedTab === index
                                        ? tabColor
                                            ? isDarkColor(tabColor)
                                                ? themeColors.pureWhite
                                                : themeColors.pureBlack
                                            : alternate
                                            ? themeColors.neutralBlack
                                            : themeColors.neutralWhite
                                        : hoveredTab === index
                                        ? themeColors.neutralBlack
                                        : themeColors.neutral3,
                                zIndex: 1,
                                opacity: disabled ? 0.5 : 1,
                                minWidth: width,
                                maxWidth: width,
                                textAlign: "center",
                            }}>
                            <ConditionalLink
                                key={label}
                                condition={!!href}
                                href={href}
                                style={{
                                    color:
                                        color || selectedTab === index
                                            ? tabColor
                                                ? isDarkColor(tabColor)
                                                    ? themeColors.pureWhite
                                                    : themeColors.pureBlack
                                                : alternate
                                                ? themeColors.neutralBlack
                                                : themeColors.neutralWhite
                                            : hoveredTab === index
                                            ? themeColors.neutralBlack
                                            : themeColors.neutral3,
                                }}>
                                {label}
                            </ConditionalLink>
                        </span>
                    </Box>
                </ConditionalTooltip>
            ))}
            <div
                style={{
                    position: "absolute",
                    top: "5px",
                    bottom: "5px",
                    backgroundColor: tabColor || (alternate ? themeColors.neutralWhite : themeColors.neutralBlack),
                    borderRadius: "4.5rem",
                    willChange: "transform",
                    pointerEvents: "none",
                    width: width,
                    transform: `translateX(${currentTabLeft})`,
                    transition: "transform ease 0.4s",
                }}
            />
        </div>
    );
};

export default TabSwitcher;
