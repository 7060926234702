import LazyLoaded from "@/components/LazyLoaded";
import InstallAppPopup from "@/components/Popup/InstallAppPopup";
import { useMobileAppInvitation } from "@/utils/mobileAppUtils";
import { getPrevPath } from "@/utils/pathUtils";
import { usePathname } from "next/navigation";
import { useEffect } from "react";

const SuspensedMobileAppPopup = () => {
    const currentPathname = usePathname();

    const {
        shouldOpenPopupByUrl: shouldDisplayMobileAppPopup,
        isMobAppPopupOpen,
        closePopup: closeMobileAppPopup,
    } = useMobileAppInvitation();

    useEffect(() => {
        const previousPath = getPrevPath();
        shouldDisplayMobileAppPopup(currentPathname, previousPath);
    }, [currentPathname]);

    return (
        <LazyLoaded load={isMobAppPopupOpen}>
            <InstallAppPopup isOpen={isMobAppPopupOpen} onClose={closeMobileAppPopup} />
        </LazyLoaded>
    );
};

export default SuspensedMobileAppPopup;
