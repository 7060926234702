"use client";

import { ExclusiveUserContent } from "@knowt/syncing/types/common";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { Key, Lock, Share, Unlock } from "lucide-react";
import React from "react";
// eslint-disable-next-line no-restricted-imports
import { SxProps } from "@mui/material";
import Circular from "@/components/Circular";
import { useSharingPopupContextSelector } from "./hooks/useSharingPopupContext";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { shareOrCopy } from "@/utils/clipboard";
import { iconSizes } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import toast from "react-hot-toast";
import { isFolder } from "@knowt/syncing/utils/dataCleaning";
import CircularButton from "@/components/CircularButton";

const SharingPillWithHandler = ({
    media,
    note,
    flashcardSet,
    folder,
    onClose,
    mutate,
    sx,
    circularSx,
    iconColor,
    iconOnly,
    ...props
}: ExclusiveUserContent & {
    onClose?: ({ isPublic, prevPublic }: { isPublic?: boolean | null; prevPublic?: boolean | null }) => void;
    mutate?: () => void;
    sx?: SxProps;
    circularSx?: Omit<SxProps, "backgroundColor"> & { backgroundColor?: string };
    iconColor?: string;
    iconOnly?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>) => {
    const { userId } = useCurrentUser();
    const openSharingPopup = useSharingPopupContextSelector(state => state.openSharingPopup);

    const item = media || note || flashcardSet || folder;

    const isPublic = item?.public;
    const isPasswordProtected = !!item?.password;

    const isOwner = item?.userId === userId;

    const handleShareClick = async () => {
        if (media) {
            return toast.error("coming soon!");
        }

        if (isOwner) {
            const exclusiveItem = { media, note, flashcardSet, folder } as ExclusiveUserContent;
            openSharingPopup({ ...exclusiveItem, onClose, mutate });
        } else {
            await shareOrCopy({ title: (isFolder(item) ? item?.name : item?.title) || "Knowt" });
        }
    };

    if (iconOnly) {
        return (
            <CircularButton
                radius={"3.8rem"}
                style={{
                    backgroundColor: isPublic ? themeColors.icon1 : isPasswordProtected ? "#C83E80" : "#000",
                    ...circularSx,
                }}
                onClick={handleShareClick}>
                {!isOwner ? (
                    <Share size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                ) : isPublic ? (
                    <Unlock size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                ) : isPasswordProtected ? (
                    <Key size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                ) : (
                    <Lock size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                )}
            </CircularButton>
        );
    }

    return (
        <CircularRectTextButton
            {...props}
            className="secondaryTextBold1"
            sx={{
                backgroundColor: themeColors.neutralWhite,
                color: themeColors.neutralBlack,
                padding: spacing.XS,
                paddingRight: spacing.SM,
                gap: spacing.XS,
                width: "12rem",
                height: "4rem",
                justifyContent: "flex-start",
                "&:hover": {
                    backgroundColor: themeColors.neutralBlack,
                    color: themeColors.neutralWhite,
                },
                ...sx,
            }}
            onClick={handleShareClick}
            tooltip={"edit sharing settings"}>
            <Circular
                radius={"3rem"}
                style={{
                    backgroundColor: isPublic ? themeColors.icon1 : isPasswordProtected ? "#C83E80" : "#000",
                    ...circularSx,
                }}>
                {!isOwner ? (
                    <Share size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                ) : isPublic ? (
                    <Unlock size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                ) : isPasswordProtected ? (
                    <Key size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                ) : (
                    <Lock size={iconSizes.SM_S} color={iconColor ?? themeColors.pureWhite} />
                )}
            </Circular>
            <p>{!isOwner ? "share" : isPublic ? "sharing" : "share"}</p>
        </CircularRectTextButton>
    );
};

export default SharingPillWithHandler;
