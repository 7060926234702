import { useState } from "react";
import { useSearchParams } from "next/navigation";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";

const LOCAL_INV_KEY = "mobile-app-invitation";

export const useMobileAppInvitation = () => {
    const { user } = useCurrentUser();
    const [isMobAppPopupOpen, setIsMobAppPopupOpen] = useState(false);

    const searchParams = useSearchParams();

    const getLocalMobileAppDownloadInvitation = () => {
        const localInvitation = localStorage.getItem(LOCAL_INV_KEY);

        if (!localInvitation) {
            localStorage.setItem(LOCAL_INV_KEY, "false");
            return false;
        }

        const alreadyInvited = JSON.parse(localInvitation);

        return alreadyInvited;
    };

    const shouldDisplayInvitation = () => {
        const alreadyInvited = getLocalMobileAppDownloadInvitation();

        if (!alreadyInvited && user) {
            setIsMobAppPopupOpen(true);
        }
    };

    const setInvitationDisplayed = () => {
        localStorage.setItem(LOCAL_INV_KEY, "true");
    };

    const shouldOpenPopupByUrl = (currentPath: string, previousPath: string | null) => {
        const isFlashcardSet = currentPath.includes("flashcards");
        const isNote = currentPath.includes("note");

        const firstFlashcardSetCreated = isFlashcardSet && searchParams?.get("isNew") === "true";
        const firstNoteLeaved = previousPath?.includes("note") && previousPath.includes("isNew");

        const firstFlashcardCardSetVisited =
            isFlashcardSet && !currentPath.includes("edit") && !firstFlashcardSetCreated;

        const firstNoteVisited = isNote && searchParams?.get("isNew") !== "true";

        const shouldDisplay =
            firstFlashcardSetCreated || firstNoteLeaved || firstFlashcardCardSetVisited || firstNoteVisited;

        if (shouldDisplay) {
            shouldDisplayInvitation();
        }
    };

    const closePopup = () => {
        setIsMobAppPopupOpen(false);
        setInvitationDisplayed();
    };

    return {
        shouldDisplayInvitation,
        setInvitationDisplayed,
        shouldOpenPopupByUrl,
        isMobAppPopupOpen,
        closePopup,
    };
};
