import { LucideIcon } from "lucide-react";
import Link from "@/components/wrappers/Link";
import classes from "@/components/Footer/socialMediaButton.module.css";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import CircularButton from "@/components/CircularButton";

type SocialMediaButtonProps = {
    href: string;
    color?: string;
    Icon?: LucideIcon;
    size?: number;
    label: string;
    children?: React.ReactNode;
};

const SocialMediaButton = ({
    href,
    color = themeColors.neutralBlack,
    Icon,
    size = iconSizes.MD,
    label = "Checkout Knowt Social Media",
    children,
}: SocialMediaButtonProps) => {
    return (
        <Link aria-label={"Checkout Knowt " + label} className={classes.iconbutton} href={href} target="_blank">
            <CircularButton radius="5rem">
                {Icon ? <Icon size={size} color={color} strokeWidth={strokeWidth.normal} fill={color} /> : children}
            </CircularButton>
        </Link>
    );
};

export default SocialMediaButton;
