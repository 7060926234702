"use client";

import awsConfig from "@/config/aws_v6";
import { amplifyAuthOverride } from "@knowt/syncing/hooks/user/utils";
import { Amplify } from "aws-amplify";

// Amplify.Logger.LOG_LEVEL = "DEBUG";

Amplify.configure(awsConfig, {
    ssr: true,
    ...amplifyAuthOverride,
});

export default function ConfigureAmplify() {
    return null;
}
