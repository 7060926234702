import { getUnixTimeFromDDMMYY } from "@/utils/dateTimeUtils";

export type GuidedEvent = GuidedEventObject[];

export type GuidedEvents = {
    [key in keyof GuidedEventsKeys]: GuidedEvent;
};

export enum GuidedEventUserPropType {
    LESS,
    MORE,
}

export enum GuidedEventAction {
    ADD,
    REMOVE,
}

export type GuidedEventObject = {
    eventName: string;
    visitCount?: number;
    userProp?: {
        created: number;
        type: GuidedEventUserPropType;
    };
    action?: GuidedEventAction;
};

export type GuidedEventsKeys = {
    ON_HOME_PAGE: string;
    AD_BLOCKER_MIXPANEL: string;
    USER_FEEDBACK: string;
    CLASS_DASHBOARD: string;
};

const GUIDED_EVENTS: GuidedEvents = {
    ON_HOME_PAGE: [
        {
            eventName: "adBlocker",
            visitCount: 1,
        },
        {
            eventName: "experience",
            visitCount: 1,
        },
        {
            eventName: "sept2023",
            userProp: {
                created: getUnixTimeFromDDMMYY("01-09-2023") / 1000, // backend expects in seconds (not ms)
                type: GuidedEventUserPropType.LESS, // only show this event to users created before sep 2023
            },
        },
        {
            eventName: "chx",
            userProp: {
                created: getUnixTimeFromDDMMYY("01-09-2023") / 1000, // backend expects in seconds (not ms)
                type: GuidedEventUserPropType.LESS, // only show this event to users created before sep 2023
            },
        },

        {
            // IMPORTANT: don't remove this, only proof for agreement! in case of a lawsuit
            eventName: "privacy-agreement-march-2024",
            userProp: {
                created: getUnixTimeFromDDMMYY("01-04-2024") / 1000, // backend expects in seconds (not ms)
                type: GuidedEventUserPropType.LESS, // only show this event to users created before Apr 2024
            },
        },
    ],

    AD_BLOCKER_MIXPANEL: [{ eventName: "adblockmx", visitCount: 2 }],
    USER_FEEDBACK: [{ eventName: "referral-feedback" }, { eventName: "goal-feedback" }],
    CLASS_DASHBOARD: [{ eventName: "class-intro" }],
};

export enum AllEventNames {
    ADBLOCKER = "adBlocker",
    EXPERIENCE = "experience",
    SEPT2023 = "sept2023",
    CHX = "chx",
    ADBLOCKMX = "adblockmx",
    REFERRAL_FEEDBACK = "referral-feedback",
    GOAL_FEEDBACK = "goal-feedback",
    CLASS_INTRO = "class-intro",
    PRIVACY_AGREEMENT_MARCH_2024 = "privacy-agreement-march-2024",
}

export const allEventNamesList = Object.values(GUIDED_EVENTS)
    .reduce((acc, curr) => [...acc, ...curr], [])
    .map(event => event.eventName);

export default GUIDED_EVENTS;
