import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/EmotionCache.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/MaybeCopyReferralCode.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/MaybeScrollToAnchor.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/page/rootComponents/AuthDialogProviders.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/page/rootComponents/ConfigureAmplify.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/page/rootComponents/LoggingSetup.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/page/rootComponents/TopLevelComponents.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/page/rootComponents/WebVitals.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/RefreshChromeExtensionSession.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/TopProgressBar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/analytics/GoogleAnalytics.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/Feedback/FeedbackFlowContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/Popup/AiPopup/AiPrivacyPopupContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/Popup/InitialAccountSetupPopup/InitialAccountSetupContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/Popup/Subscription/UpgradePopupContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/ssr/ConfigureSyncing.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/contexts/CardPreviewContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/contexts/DialogContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/contexts/MultiTabsEditingWarningContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/features/ShareSettings/ClassSharingPillWithHandler.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/features/ShareSettings/hooks/useClassSharingPopupContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/features/ShareSettings/hooks/useSharingPopupContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/features/ShareSettings/PasswordProtectedComponent.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/features/ShareSettings/SharingPillWithHandler.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/features/Tagging/index.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/styles/global/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./../public/fonts/bw-modelica/BwModelica-SS01-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./../public/fonts/bw-modelica/BwModelica-SS01-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./../public/fonts/bw-modelica/BwModelica-SS01-ExtraBold.woff\",\"weight\":\"800\",\"style\":\"normal\"}],\"variable\":\"--knowt-font-name\"}],\"variableName\":\"bwModellica\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/syncing/hooks/guidedEvents/UserAlerts.tsx")