"use client";
import {
    allEventNamesList,
    GuidedEventAction,
    GuidedEventObject,
} from "@knowt/syncing/hooks/guidedEvents/guidedEvents";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { createContext, useContextSelector } from "@/utils/use-context-selector";
import { generateUpdatedUserAlerts, UpdateUserAlerts, UpdateUserAlertsMethod } from "@/hooks/guidedEvents/utils";
import { safeParse } from "@/utils/stringUtils";
import { updateUserInfo } from "../user/utils";

export type UserAlertsType =
    | {
          done: string[];
          todo: GuidedEventObject[];
      }
    | undefined;

type UserAlertsContextValue = {
    userAlerts: UserAlertsType;
    addTodoEvent: (event: GuidedEventObject) => void;
    closeEvent: (eventName: string) => void;
    removeFromDone: (eventName: string) => void;
    decrementVisitCount: (event: GuidedEventObject) => void;
    removeFromTodo: (event: GuidedEventObject) => void;
    refreshUserAlerts: ({ events }: { events: GuidedEventObject[] }) => {
        eventsToStage: GuidedEventObject[];
        eventsToRemoveFromDone: string[];
        eventsToRemoveFromTodo: GuidedEventObject[];
        eventsToDecrementVisitCount: GuidedEventObject[];
    };
    allStagedEvents?: string[];
    setAllStagedEvents?: Dispatch<SetStateAction<string[]>>;
};

const UserAlertsContext = createContext<UserAlertsContextValue>(null);

export const UserAlertsContextProvider = ({ children }) => {
    const { user } = useCurrentUser();

    const userAlerts = useMemo(
        () =>
            (safeParse(user?.alerts) ?? {
                done: [],
                todo: [],
            }) as UserAlertsType,
        [user]
    );

    const [pendingUpdates, setPendingUpdates] = useState<UpdateUserAlerts[]>([]);
    const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
    const [allStagedEvents, setAllStagedEvents] = useState<string[]>([]);

    const cleanDuplicateEvents = useCallback((data: UserAlertsType) => {
        const { done, todo } = data;

        const cleanedDone = [...new Set(done)];

        const cleanedTodo = [...new Set(todo)]?.filter((event: GuidedEventObject) => {
            const isEventDone = cleanedDone.includes(event.eventName);
            const isActionToAdd = !event.action;

            return !(isEventDone && isActionToAdd);
        });

        return {
            done: cleanedDone,
            todo: cleanedTodo,
        };
    }, []);

    const enqueueUpdateUserAlerts = useCallback(
        (updateAction: UpdateUserAlerts) => {
            if (pendingUpdates.includes(updateAction)) return;

            setPendingUpdates(prev => [...prev, updateAction]);
        },
        [pendingUpdates]
    );

    const commitUpdateUserAlerts = useCallback(async () => {
        const newData = cleanDuplicateEvents(
            generateUpdatedUserAlerts({ userAlerts, updatedFields: pendingUpdates[0] })
        );

        await updateUserInfo({ alerts: JSON.stringify(newData) });

        setPendingUpdates(prev => [...new Set(prev.slice(1))]);
    }, [cleanDuplicateEvents, pendingUpdates, userAlerts]);

    useEffect(() => {
        if (!pendingUpdates.length || isUpdateInProgress) return;

        setIsUpdateInProgress(true);

        commitUpdateUserAlerts().finally(() => setIsUpdateInProgress(false));
    }, [commitUpdateUserAlerts, isUpdateInProgress, pendingUpdates, userAlerts]);

    const addTodoEvent = useCallback(
        (event: GuidedEventObject) => {
            if (!userAlerts) return;

            enqueueUpdateUserAlerts({
                method: UpdateUserAlertsMethod.ADD_TO_TODO,
                event,
            });
        },
        [enqueueUpdateUserAlerts, userAlerts]
    );

    const closeEvent = useCallback(
        (eventName: string) => {
            if (!userAlerts) return;

            enqueueUpdateUserAlerts({
                method: UpdateUserAlertsMethod.ADD_TO_DONE,
                eventName,
            });
        },
        [enqueueUpdateUserAlerts, userAlerts]
    );

    const removeFromDone = useCallback(
        (eventName: string) => {
            if (!userAlerts) return;

            enqueueUpdateUserAlerts({
                method: UpdateUserAlertsMethod.REMOVE_FROM_DONE,
                eventName,
            });
        },
        [enqueueUpdateUserAlerts, userAlerts]
    );

    const removeFromTodo = useCallback(
        (event: GuidedEventObject) => {
            if (!userAlerts) return;

            enqueueUpdateUserAlerts({
                method: UpdateUserAlertsMethod.REMOVE_FROM_TODO,
                event,
            });
        },
        [enqueueUpdateUserAlerts, userAlerts]
    );

    const decrementVisitCount = useCallback(
        (event: GuidedEventObject) => {
            if (!userAlerts) return;

            enqueueUpdateUserAlerts({
                method: UpdateUserAlertsMethod.DECREMENT_VISIT_COUNT,
                event,
            });
        },
        [enqueueUpdateUserAlerts, userAlerts]
    );

    const refreshUserAlerts = useCallback(
        ({ events }) => {
            let eventsToStage: GuidedEventObject[] = [];
            let eventsToRemoveFromDone: string[] = [];
            let eventsToRemoveFromTodo: GuidedEventObject[] = [];
            let eventsToDecrementVisitCount: GuidedEventObject[] = [];

            const currentEventNamesList = events?.map(event => event.eventName);

            const refreshDoneList = (doneList: string[]) => {
                const possibleEventsToRemoveFromDone = doneList.filter(
                    (event: string) => !allEventNamesList.includes(event)
                );

                return possibleEventsToRemoveFromDone.forEach((event: string) => {
                    eventsToRemoveFromDone = [...eventsToRemoveFromDone, event];
                });
            };

            const refreshTodoList = (todoList: GuidedEventObject[]) => {
                const todoListWithDeletedEventsRemoved = todoList.filter((event: GuidedEventObject) =>
                    allEventNamesList.includes(event.eventName)
                );

                const possibleEventsToRemoveFromTodo = todoList.filter(
                    (event: GuidedEventObject) => !allEventNamesList.includes(event.eventName)
                );

                possibleEventsToRemoveFromTodo.forEach(event => {
                    eventsToRemoveFromTodo = [...eventsToRemoveFromTodo, event];
                });

                todoListWithDeletedEventsRemoved.forEach((event: GuidedEventObject) => {
                    const shouldSkipForThisHookCall = !currentEventNamesList.includes(event.eventName);
                    if (shouldSkipForThisHookCall) return event;

                    if (event.visitCount - 1 > 0) {
                        eventsToDecrementVisitCount = [...eventsToDecrementVisitCount, event];
                        return;
                    }

                    if (event.action === GuidedEventAction.REMOVE) removeFromDone(event.eventName);

                    eventsToStage = [...eventsToStage, event];

                    return undefined;
                });
            };

            Object.values(userAlerts)?.map((alerts, index) => {
                if (index === 0) return refreshDoneList(alerts as string[]);

                return refreshTodoList(alerts as GuidedEventObject[]);
            });

            return {
                eventsToStage,
                eventsToRemoveFromDone,
                eventsToRemoveFromTodo,
                eventsToDecrementVisitCount,
            };
        },
        [removeFromDone, userAlerts]
    );

    const values = {
        userAlerts,
        addTodoEvent,
        closeEvent,
        removeFromDone,
        decrementVisitCount,
        removeFromTodo,
        refreshUserAlerts,
        allStagedEvents,
        setAllStagedEvents,
    };

    return <UserAlertsContext.Provider value={values}>{children}</UserAlertsContext.Provider>;
};

export const useUserAlertsSelector = <T,>(selector: (state: UserAlertsContextValue) => T): T =>
    useContextSelector(UserAlertsContext, selector);
