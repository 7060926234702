import { platform } from "@knowt/syncing/platform";

if (typeof window !== "undefined") {
    platform.configure({
        storage: () => import("@/utils/LocalKeyValueStore").then(m => m.default),
        toast: () => import("react-hot-toast").then(m => m.toast),
        timezone: () => import("@/utils/timezone").then(m => m.default),
        anonymousViewerUid: () => import("@/utils/anonymousUserId").then(m => m.default),
        dataCleaning: async () => {
            const { getHtmlFromContent } = await import("@knowt/editor/helpers/getHtmlFromContent");
            const { getPlainTextFromContent } = await import("@knowt/editor/helpers/getPlainTextFromContent");
            return { getHtmlFromContent, getPlainTextFromContent };
        },
        fetch: async () => fetch,
        analytics: {
            mixpanel: () => import("@/utils/analytics/Mixpanel").then(m => m.default),
            logging: () => import("@/utils/analytics/logging"),
        },
        events: async () => ({
            on: typeof window !== "undefined" ? window.addEventListener : undefined,
            off: typeof window !== "undefined" ? window.removeEventListener : undefined,
        }),
    });
}
