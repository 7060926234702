"use client";

import { ItemType, UpgradeEvent } from "@knowt/syncing/graphql/schema";
import noop from "@knowt/syncing/utils/noop";
import { useState } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import Mixpanel from "@/utils/analytics/Mixpanel";

type AdditionalContext = {
    itemId?: string;
    itemType?: ItemType;
};

export type OpenUpgradePopupProps = {
    event: UpgradeEvent;
    context: AdditionalContext;
    onClose?: () => void;
};

type UpgradePopupContextProps = {
    isOpen: boolean;
    event: UpgradeEvent;
    context: AdditionalContext;
    onClose?: () => void;
    openUpgradePopup: (props: OpenUpgradePopupProps) => void;
    closeUpgradePopup: () => void;
};

const UpgradePopupContext = createContext<UpgradePopupContextProps>({
    isOpen: false,
    event: UpgradeEvent.MAX_PROMPT,
    context: {},
    onClose: noop,
    openUpgradePopup: noop,
    closeUpgradePopup: noop,
});

export const UpgradePopupContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [event, setEvent] = useState(UpgradeEvent.MAX_PROMPT);
    const [context, setContext] = useState({});
    const [onClose, setOnClose] = useState<() => void>(noop);

    const openUpgradePopup = ({ event, context: _context = {}, onClose = noop }: OpenUpgradePopupProps) => {
        Mixpanel.track(`Upgrade Popup`, { event, ...context });
        setEvent(event);
        setIsOpen(true);
        setOnClose(onClose);
        setContext(_context);
    };

    const closeUpgradePopup = () => {
        setIsOpen(false);
        onClose?.();
        // delay resetting props so that the UI does not change on close animation fade away
        setTimeout(() => {
            setEvent(UpgradeEvent.MAX_PROMPT);
        }, 300);
    };

    return (
        <UpgradePopupContext.Provider value={{ isOpen, event, context, onClose, openUpgradePopup, closeUpgradePopup }}>
            {children}
        </UpgradePopupContext.Provider>
    );
};

export const useUpgradePopupContextSelector = <T,>(selector: (state: UpgradePopupContextProps) => T) =>
    useContextSelector(UpgradePopupContext, selector);
