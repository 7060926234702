"use client";

import React from "react";
import { AuthPopupContextProvider, AuthSlideContextProvider } from "@/features/Auth";

/**
 * For now, we don't dynamically import, since it will cause a screen flash as the components load in
 */
const AuthDialogProviders = ({ children }: { children: React.ReactNode }) => {
    return (
        <AuthSlideContextProvider>
            <AuthPopupContextProvider>{children}</AuthPopupContextProvider>
        </AuthSlideContextProvider>
    );
};

export default AuthDialogProviders;
