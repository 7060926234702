import { Facebook, Linkedin, Twitter } from "lucide-react";
import Link from "@/components/wrappers/Link";
import { CSSProperties } from "react";
import SocialMediaButton from "./SocialMediaButton";
import classes from "./footer.module.css";
import { themeColors } from "@/utils/themeColors";
import { URLS } from "@/config/constants";
import { InstagramIcon, TiktokIcon } from "./CustomIcons";
import { FooterAds } from "@/features/Ads";

const GET_KNOWT_LINKS = [
    { href: "/mobile", text: "Mobile App" },
    { href: "/chrome-extension", text: "Chrome Extension" },
    { href: "/knowttakers", text: "KnowtTakers" },
    { href: "https://connect.studentbeans.com/v4/hosted/knowt/us", text: "Educational Discounts" },
    // TODO: add link for schools
    // { href: "/", text: "Knowt For Schools" },
];

const RESOURCES_LINKS = [
    // TODO: is it the right link for FAQ?
    { href: "/help", text: "FAQ" },
    { href: "/contact/", text: "Contact Us" },
    { href: "/blog/", text: "Blog" },
    { href: "/updates", text: "Knowt Updates" },
    { href: URLS.DMCA_TAKEDOWN_REQUEST, text: "DMCA Takedown", openInNewTab: true },
    { href: "/privacy/", text: "Privacy Policy" },
    { href: "/coppa-notice/", text: "COPPA Notice" },
    { href: "/terms/", text: "Terms of Service" },
    { href: "/features", text: "Feature Requests" },
];

// TODO: we need to add pricing somewhere?
// { href: "/plans/", text: "Pricing" },

const STUDY_TOOLS_LINKS = [
    { href: "/", text: "AI Flashcards" },
    { href: "/ai-pdf-summarizer", text: "AI PDF Summarizer" },
    { href: "/ai-powerpoint-summarizer", text: "AI PPT Summarizer" },
    { href: "/ai-video-summarizer", text: "AI Video Summarizer" },
    { href: "/ai-spreadsheet-summarizer", text: "AI Spreadsheet Summarizer" },
    { href: "/ai-notes", text: "AI Notes" },
];

const EXAM_TYPE_LINKS = [
    { href: "/exams/AP", text: "AP Exam Hub" },
    { href: "/exams/IB", text: "IB Exam Hub" },
    { href: "/exams/GCSE", text: "GCSE Exam Hub" },
    { href: "/exams/A-Level", text: "A Level Exam Hub" },
    { href: "/exams", text: "More Exam Hubs" },
];

const SUBJECT_LINKS = [
    { href: "/subject/Science", text: "Science" },
    { href: "/subject/Social-Studies", text: "Social Studies" },
    { href: "/subject/Language", text: "Language" },
    { href: "/subject/Math", text: "Math" },
    { href: "/subject/Engineering", text: "Engineering" },
    { href: "/subject/Business", text: "Business" },
];

type ItemLinkProps = {
    href: string;
    text: string;
    openInNewTab?: boolean;
};

const ItemLink = ({ href, text, openInNewTab }: ItemLinkProps) => (
    <Link href={href} openInNewTab={openInNewTab}>
        <div className={classes.itemText}>{text}</div>
    </Link>
);

type Props = {
    ad?: boolean;
    style?: CSSProperties;
};

const FooterColumn = ({
    title,
    links,
    privacy = false,
}: {
    title: string;
    links: ItemLinkProps[];
    privacy?: boolean;
}) => {
    return (
        <div className={classes.column}>
            <div className={classes.title}>{title}</div>
            <div className={classes.items}>
                {links.map(item => (
                    <ItemLink key={item.text} {...item} />
                ))}
                {privacy && (
                    <div className={classes.itemText} id="pmLink">
                        Privacy Manager
                    </div>
                )}
            </div>
        </div>
    );
};

const BottomRow = () => {
    return (
        <div className={classes.contactContainer}>
            <div>
                <div className={classes.incText}>© 2024 Knowt Inc.</div>
            </div>
            <div className={classes.iconWrapper}>
                <SocialMediaButton label="Tiktok" href="https://www.tiktok.com/@getknowt">
                    {TiktokIcon}
                </SocialMediaButton>
                <SocialMediaButton label="Instagram" href="https://instagram.com/getknowt">
                    {InstagramIcon}
                </SocialMediaButton>
                <SocialMediaButton label="Facebook" Icon={Facebook} href="https://www.facebook.com/getknowt" />
                <SocialMediaButton label="Twitter" Icon={Twitter} href="https://twitter.com/getknowt" />
                <SocialMediaButton label="Linkedin" Icon={Linkedin} href="https://www.linkedin.com/company/knowt" />
            </div>
        </div>
    );
};

const APCopyrightNotice = () => {
    const message =
        "Advanced Placement® AP®, and SAT® are trademarks registered by the College Board, which is not affiliated with, and does not endorse, this product. ACT® is a trademark registered by the ACT, Inc, which is not affiliated with, and does not endorse, this product.";

    return (
        <div className={classes.apNotice}>
            <p>{message}</p>
        </div>
    );
};

const Footer = ({ ad = false, style }: Props) => {
    return (
        <footer
            className={classes.footerComponent}
            style={{ boxShadow: `0 0 4px 0 ${themeColors.shadow}`, paddingBottom: ad ? "110px" : "10px", ...style }}>
            <div className={classes.container}>
                <div className={classes.grid}>
                    <FooterColumn title="Get Knowt" links={GET_KNOWT_LINKS} />
                    <FooterColumn title="Study Tools" links={STUDY_TOOLS_LINKS} />
                    <FooterColumn title="Exams" links={EXAM_TYPE_LINKS} />
                    <FooterColumn title="Resources" links={RESOURCES_LINKS} privacy />
                    <FooterColumn title="Subjects" links={SUBJECT_LINKS} />
                </div>
                <BottomRow />
                <APCopyrightNotice />
            </div>
            {ad && <FooterAds />}
        </footer>
    );
};

export default Footer;
