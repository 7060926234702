import { forwardRef, useState } from "react";
import { themeColors } from "@/utils/themeColors";
import CircularButton, { CircularButtonProps } from "@/components/CircularButton";
import LoadingAnimation from "@/components/LoadingAnimation";
import { Rem } from "@/types/common";
import { LucideIcon, LucideProps } from "lucide-react";
import { strokeWidth } from "@/utils/iconProps";

export type CircularHoverIconProps = {
    radius?: Rem;
    tabIndex?: number;
    buttonColor?: string;
    size: number;
    fill?: string;
    color?: string;
    hoverColor?: string;
    fillWhenActive?: boolean;
    isLoading?: boolean;
    isActive?: boolean;
    isDisabled?: boolean;
    activeColor?: string;
    onClick?: (e) => void;
    Icon: LucideIcon;
    className?: string;
    tooltip?: string;
    buttonProps?: Partial<CircularButtonProps>;
    iconProps?: Partial<LucideProps>;
};

export const CircularFilledIcon = forwardRef(
    (
        {
            radius = "3.8rem",
            tabIndex,
            isDisabled,
            buttonColor = isDisabled ? "rgb(146, 146, 146, 0.3)" : themeColors.neutralWhite,
            color = themeColors.neutralBlack,
            hoverColor = themeColors.neutralBlack,
            isLoading = false,
            fill,
            fillWhenActive,
            isActive,
            activeColor = themeColors.primary,
            size,
            onClick,
            Icon,
            className,
            tooltip,
            buttonProps,
            iconProps,
        }: CircularHoverIconProps,
        ref
    ) => {
        const [isHover, setIsHover] = useState(false);

        const isButton = !!onClick;
        const isHoverable = isButton && !isDisabled;

        return (
            <CircularButton
                ref={ref}
                tabIndex={tabIndex}
                component={isButton ? "button" : "div"}
                className={className}
                radius={radius}
                onMouseOver={() => setIsHover(isHoverable)}
                onMouseOut={() => setIsHover(false)}
                onClick={e => {
                    if (isDisabled || !isButton) return;
                    e.stopPropagation();
                    e.preventDefault();
                    onClick?.(e);
                }}
                sx={{
                    "&:active": {
                        transform: "scale(0.95)",
                    },
                    "&:hover": {
                        backgroundColor: hoverColor,
                    },
                    borderRadius: 999,
                    backgroundColor: buttonColor,
                    transition: "all ease 0.2s",
                    ...buttonProps?.sx,
                }}
                tooltip={tooltip}
                title={undefined}
                {...buttonProps}>
                {isLoading ? (
                    <LoadingAnimation size={24} />
                ) : (
                    <Icon
                        size={size}
                        strokeWidth={strokeWidth.normal}
                        fill={isActive && fillWhenActive ? fill : "transparent"}
                        color={isActive ? fill ?? activeColor : isHover ? buttonColor : color}
                        {...iconProps}
                    />
                )}
            </CircularButton>
        );
    }
);

CircularFilledIcon.displayName = "CircularFilledIcon";
export default CircularFilledIcon;
