"use client";

import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { SubscriptionType } from "@knowt/syncing/graphql/schema";
import { useEffect } from "react";

const AdUnit = () => {
    const { smDown } = useBreakPoints();

    useEffect(() => {
        if (smDown) {
            return;
        }

        if (typeof window !== "undefined") {
            window.freestar = window.freestar || {};
            window.freestar.queue = window.freestar.queue || [];

            window.freestar.queue.push(function () {
                window.freestar.newStickyFooter();
            });
        }

        return () => {
            if (typeof window !== "undefined") {
                window.freestar = window.freestar || {};
                window.freestar.queue = window.freestar.queue || [];

                window.freestar.queue.push(function () {
                    window.freestar.deleteStickyFooter();
                });
            }
        };
    }, []);

    return null;
};

const FooterAds = () => {
    const { userId, subscriptionType, organization, loginInProgress } = useCurrentUser();

    if (!userId || organization?.dpa) return null;

    return subscriptionType === SubscriptionType.BASIC && !loginInProgress && <AdUnit />;
};

export default FooterAds;
