"use client";

import { useIsomorphicLayoutEffect } from "react-use";

export default function ConfigureSyncing() {
    useIsomorphicLayoutEffect(() => {
        require("@/configureSyncing");
    }, []);
    return null;
}
