"use client";

import { useState } from "react";
import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import TabSwitcher from "@/components/TabSwitcher";
import Image from "next/image";
import { ASSETS_URL } from "@/config/deployConstants";
import LinkWrapper from "@/components/wrappers/Link/Link";
import ResponsiveDialog, { DialogCloseBtn } from "@/features/ResponsiveDialog";
import br from "@/styles/breakpoints.module.css";

const iosMobileAppQRCode = `${ASSETS_URL}/images/mobile-qrcode-ios.svg`;
const androidMobileAppQRCode = `${ASSETS_URL}/images/mobile-qrcode-android.svg`;
const kaiIOS = `${ASSETS_URL}/images/kai-ios.svg`;
const kaiAndroid = `${ASSETS_URL}/images/kai-android.svg`;

const tabs = [
    {
        label: "App Store",
        tootip: "Download Knowt's Apple mobile app",
    },
    {
        label: "Play Store",
        tootip: "Download Knowt's Android mobile app",
    },
];

const tabsContent = {
    0: {
        qrCode: iosMobileAppQRCode,
        kai: kaiIOS,
        alt: "Download Knowt's Apple mobile app",
    },
    1: {
        qrCode: androidMobileAppQRCode,
        kai: kaiAndroid,
        alt: "Download Knowt's Android mobile app",
    },
};

type InstallAppPopupProps = {
    isOpen: boolean;
    onClose: () => void;
};

const PopupBody = () => {
    const [tab, setTab] = useState(0);

    return (
        <>
            <div className={br.smDownDisplayNone}>
                <TabSwitcher
                    tabs={tabs}
                    selectedTab={tab}
                    setSelectedTab={setTab}
                    width={"13.5rem"}
                    height="5.6rem"
                    fontSize={"1.4rem"}
                />
            </div>

            <FlexRowAlignCenter
                className={br.smDownColumn}
                style={{ gap: "3.5rem", padding: "2rem", justifyContent: "center" }}>
                <Image
                    className={br.smDownDisplayNone}
                    src={tabsContent[tab].qrCode}
                    alt={tabsContent[tab].alt}
                    width={300}
                    height={300}
                />
                <Image src={tabsContent[tab].kai} alt="Download Knowt's Apple mobile app" width={231} height={279} />
            </FlexRowAlignCenter>

            <FlexColumnAlignJustifyCenter style={{ textAlign: "center", gap: "1rem" }}>
                <h4 className="heading4">Take Knowt on the go with our mobile app!</h4>
                <span className="body1" style={{ maxWidth: "57.4rem" }}>
                    {"Scan the QR code to have Knowt everywhere you go in <10 seconds."}
                </span>
            </FlexColumnAlignJustifyCenter>

            <FlexColumnAlignJustifyCenter className={br.smUpDisplayNone} style={{ gap: "3.5rem" }}>
                <FlexRowAlignCenter style={{ gap: "1.6rem" }}>
                    <LinkWrapper href={"https://apps.apple.com/us/app/knowt-ai-flashcards-notes/id6463744184"}>
                        <Image
                            src={`${ASSETS_URL}/images/downloadon_appstore.png`}
                            alt={"mobile download"}
                            width={"121"}
                            height={"36"}
                            style={{ objectFit: "cover" }}
                        />
                    </LinkWrapper>
                    <LinkWrapper href={"https://play.google.com/store/apps/details?id=com.knowt.app"}>
                        <Image
                            src={`${ASSETS_URL}/images/downloadon_gstore.png`}
                            alt={"mobile download"}
                            width={"121"}
                            height={"36"}
                            style={{ objectFit: "cover" }}
                        />
                    </LinkWrapper>
                </FlexRowAlignCenter>
            </FlexColumnAlignJustifyCenter>
        </>
    );
};

const InstallAppPopup = ({ isOpen, onClose }: InstallAppPopupProps) => {
    return (
        <ResponsiveDialog
            isOpen={isOpen}
            style={{ gap: "3.2rem", textAlign: "center" }}
            DialogStyle={{ borderRadius: "4rem", padding: "4rem 4rem", margin: "auto", width: "80%" }}
            SlideMenuStyle={{
                padding: "2rem 2rem 3.3rem",
                flexDirection: "column",
            }}
            onClose={onClose}>
            <DialogCloseBtn
                onClick={onClose}
                forSlideMenuStyle={{ marginLeft: "auto" }}
                forDialogStyle={{ position: "absolute", top: 30, right: 30 }}
            />
            <PopupBody />
        </ResponsiveDialog>
    );
};

export default InstallAppPopup;
