"use client";

import { refreshSession } from "@knowt/syncing/hooks/user/auth";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { now } from "@knowt/syncing/utils/SyncUtils";
import { TIME_SECONDS } from "@knowt/syncing/utils/dateTimeUtils";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

export const RefreshChromeExtensionSession = () => {
    const { userId } = useCurrentUser();
    const pathname = usePathname();
    const [isExtensionInstalled, setIsExtensionInstalled] = useState<boolean | null>(null);
    const [lastSentAuth, setLastSentAuth] = useState(0);

    useEffect(() => {
        if (!window.chrome) return;
        if (!userId) return;

        const chromeExtensionId = process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID;

        if (isExtensionInstalled === null) {
            chrome.runtime?.sendMessage(chromeExtensionId, { installCheck: true }, reply => {
                setIsExtensionInstalled(reply?.confirmation === "knowt-chrome-bananas");
            });
        }

        if (isExtensionInstalled && now() - lastSentAuth > TIME_SECONDS.HOUR) {
            refreshSession().then(() => setLastSentAuth(now()));
        }
    }, [userId, pathname, isExtensionInstalled, lastSentAuth]);

    return null;
};
