import { ResourcesConfig } from "aws-amplify";
import { redirectSignIn, redirectSignOut } from "./deployConstants";
import { GRAPHQL_AUTH_MODE } from "@knowt/syncing/utils/SyncUtils";

const awsConfig: ResourcesConfig = {
    API: {
        GraphQL: {
            endpoint: "https://zayxzqrnxvaubdfpazbhvjaeoi.appsync-api.us-east-1.amazonaws.com/graphql",
            region: "us-east-1",
            defaultAuthMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
            apiKey: "lozalzpgobhprd63vfypqpece4",
        },
    },
    Auth: {
        Cognito: {
            userPoolId: "us-east-1_pj3FdFG6C",
            userPoolClientId: "linov2orokihpnfsq2aqqugsc",
            identityPoolId: "us-east-1:defa3451-ae52-434d-accb-32fa6f97d642",
            allowGuestAccess: true,
            signUpVerificationMethod: "code",
            loginWith: {
                oauth: {
                    domain: "authdev.knowt.com",
                    scopes: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
                    redirectSignIn: [redirectSignIn],
                    redirectSignOut: [redirectSignOut],
                    responseType: "code",
                },
            },
        },
    },
};

export default awsConfig;
