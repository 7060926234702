"use client";
import React, { useCallback, useMemo, useState } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import { ExclusiveUserContent } from "@knowt/syncing/types/common";
import dynamic from "next/dynamic";
import LazyLoaded from "@/components/LazyLoaded";
import toast from "react-hot-toast";
import { isMedia } from "@knowt/syncing/utils/dataCleaning";

const SharePopup = dynamic(() => import("../components/SharePopup"));

type SharingPopupContextValue = {
    openSharingPopup: ({
        media,
        note,
        flashcardSet,
        folder,
        onClose,
        mutate,
    }: {
        onClose?: ({ isPublic, prevPublic }: { isPublic?: boolean; prevPublic?: boolean }) => void;
        mutate?: () => void;
    } & ExclusiveUserContent) => void;
};

const SharingPopupContext = createContext<SharingPopupContextValue | null>(null);

export const SharingPopupContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [isSharingPopupOpen, setIsSharingPopupOpen] = useState(false);
    const [item, setItem] = useState<ExclusiveUserContent | null>(null);

    const [onCloseFunction, setOnCloseFunction] = useState<
        (({ isPublic, prevPublic }: { isPublic?: boolean | null; prevPublic?: boolean | null }) => void) | undefined
    >(undefined);

    const [mutateFunction, setMutateFunction] = useState<(() => void) | undefined>(undefined);

    const resetAllState = () => {
        setIsSharingPopupOpen(false);
        setItem(null);
        setOnCloseFunction(undefined);
        setMutateFunction(undefined);
    };

    const openSharingPopup = useCallback(
        ({
            onClose,
            mutate,
            ...item
        }: ExclusiveUserContent & {
            onClose?: ({ isPublic, prevPublic }: { isPublic?: boolean | null; prevPublic?: boolean | null }) => void;
            mutate?: () => void;
        }) => {
            if (isMedia(item)) {
                toast.error("coming soon!");
                return;
            }

            if (onClose) setOnCloseFunction(() => onClose);
            if (mutate) setMutateFunction(() => mutate);

            if (item) setIsSharingPopupOpen(true);
        },
        []
    );

    return (
        <SharingPopupContext.Provider value={useMemo(() => ({ openSharingPopup }), [openSharingPopup])}>
            {children}
            <LazyLoaded load={isSharingPopupOpen}>
                <SharePopup
                    isOpen={isSharingPopupOpen}
                    {...item}
                    onClose={({ prevPublic, isPublic }) => {
                        setIsSharingPopupOpen(false);
                        onCloseFunction?.({ prevPublic, isPublic });
                        resetAllState();
                    }}
                    mutate={mutateFunction}
                />
            </LazyLoaded>
        </SharingPopupContext.Provider>
    );
};

export const useSharingPopupContextSelector = <T,>(selector: (state: SharingPopupContextValue) => T) =>
    useContextSelector(SharingPopupContext, selector);
